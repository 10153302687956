.about .header {
	margin-bottom: 0;
}

.about .app-banner {
	background: #51b294;
	color: #fff;
}

.about .app-banner .container {
	display: flex;
	align-items: center;
	padding: 0.5em;
	justify-content: center;
}

.about .app-banner .container img {
	width: 5%;
	margin-right: 1em;
}

.about .app-banner .container p {
	margin-bottom: 0;
	margin-right: 1em;
}

.about .app-banner .container a {
	background: #eee;
	text-decoration: none;
	color: #333;
	padding: 0.5em 1em;
	transition: all 0.2s ease-in-out;
}

.about .app-banner .container a:hover,
.about .app-banner .container a:active,
.about .app-banner .container a:focus {
	opacity: 0.8;
}

.about .hero {
	display: flex;
	margin-bottom: 30px;
}

.about .hero .container {
	background: url(../img/about/hero.jpg) no-repeat;
	background-size: cover;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about .hero .hero-content {
	font-size: 1rem;
}

.about .hero .hero-copy {
	font-weight: 500;
	margin-bottom: 1em;
	font-size: 2rem;
	display: block;
}

.about-lead .speech-area {
	width: 50%;
	margin: 0 auto;
}

.about-lead .speech-area img {
	width: 15%;
}

.about-app {
	background: #51b294;
	padding: 5%;
}

.about-app .speech-area {
	width: 80%;
	margin: 0 auto;
}

.about-app .speech-area img {
	width: 40%;
}

.about-app .speech-bubble {
	padding-right: 2.5em;
	background-image: url(../img/about/speech-bubble-gray.png);
}

.about-app-logo {
	width: 65%;
	margin: 0 auto 1em;
}

.about-app-download {
	display: flex;
	width: 80%;
	margin: 0 auto;
}

.about-app-download a {
	width: 45%;
}

.about-app-download a:first-child {
	margin-right: 15%;
}

.about-app-download a.app-store {
	padding: 0 2%;
}

.about-sns {
	padding: 1rem 0 0 0;
}

.about-sns-item {
	display: inline-block;
	max-width: 30%;
}

.about-sns-item:not(:last-of-type) {
	margin-right: 2%;
}

.about-sns-item a {
	color: #333;
	font-size: 2rem;
}

